import React, { useCallback } from "react";
import {
  TableHeader,
  TableHeaderActions,
} from "@/components/molecules/table/table-header";
import { TableRows } from "@/components/molecules/table/table-rows";
import { ColumnDefinitionType } from "@/components/molecules/table/types";
import { CsvBuilder } from "filefy";

type TableProps<T, K extends keyof T> = {
  id: string;
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T, K>>;
  onRowClick?: (row: T) => void;
  startSelected?: number;
};

export const Table = <T, K extends keyof T>({
  id,
  data,
  columns,
  onRowClick,
  startSelected,
}: TableProps<T, K>): JSX.Element => {
  const [sortKey, setSortKey] = React.useState<ColumnDefinitionType<T, K>>(
    columns[0]
  );
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("asc");

  const handleTableSorting = (column: ColumnDefinitionType<T, K>) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortKey(column);
  };

  const sortData = ({
    tableData,
    sortKey,
    reverse,
  }: {
    tableData: Array<T>;
    sortKey: ColumnDefinitionType<T, K>;
    reverse: boolean;
  }) => {
    const sortedData = tableData.sort((a, b) => {
      return a[sortKey.key] > b[sortKey.key] ? 1 : -1;
    });

    if (reverse) {
      return sortedData.reverse();
    }

    return sortedData;
  };

  const sortedData = useCallback(
    () =>
      sortData({
        tableData: data,
        sortKey: sortKey,
        reverse: sortOrder === "desc",
      }),
    [data, sortKey, sortOrder]
  );

  const handleRowClick = (row: T) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const downloadTable = () => {
    const data = sortedData().map((row) => {
      return columns.map((column) => row[column.key]);
    });
    return new CsvBuilder(`${id}.csv`)
      .setDelimeter(",")
      .setColumns(columns.map((column) => column.label))
      .addRows(data as string[][])
      .exportFile();
  };

  return (
    <table className="table">
      <TableHeaderActions onDownload={() => downloadTable()} />
      <TableHeader
        columns={columns}
        onSort={(column) => handleTableSorting(column)}
        sortKey={sortKey}
      />
      {data.length > 0 ? (
        <TableRows
          tableId={id}
          data={sortedData()}
          columns={columns}
          onRowSelect={onRowClick ? handleRowClick : undefined}
          startSelected={startSelected}
        />
      ) : (
        <tbody className="table-no-data">No data</tbody>
      )}
    </table>
  );
};
