import { Tooltip } from "@mui/material";
import { Download } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

export type TableHeaderActionsProps<T, K extends keyof T> = {
  onDownload?: () => void;
};

export const TableHeaderActions = <T, K extends keyof T>({
  onDownload,
}: TableHeaderActionsProps<T, K>): JSX.Element => {
  return (
    <thead className="table-header-actions">
      <tr>
        <th>
          <Tooltip title="Download table" placement="top">
            <IconButton
              size={"small"}
              color={"primary"}
              onClick={() => {
                onDownload && onDownload();
              }}
            >
              <Download />
            </IconButton>
          </Tooltip>
        </th>
      </tr>
    </thead>
  );
};
