import React, { FC, useEffect, useState } from "react";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { Radar } from "react-chartjs-2";
import {
  ActiveElement,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { Medium, Medium2 } from "@/types";
import { reformatData } from "@/utils/common";
import { Table } from "@/components/molecules";
import { topTitlesColumns } from "@/components/molecules/table";
import { computeMediaData } from "@/components/molecules/charts";
import { RadarChartDataType } from "@/components/molecules/charts/charts-types";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

type HowToReachThemTabProps = {
  media: Medium[];
};

export const HowToReachThemTab: FC<HowToReachThemTabProps> = ({ media }) => {
  const chartRef = React.useRef<any>(null);
  const [radarIndexData, setRadarIndexData] = useState<RadarChartDataType>({
    labels: [],
    values: [],
  });
  const [radarPenetrationData, setRadarPenetrationData] =
    useState<RadarChartDataType>({
      labels: [],
      values: [],
    });
  const [topMedia, setTopMedia] = React.useState<Medium2[]>([]);
  const [selectedMedia, setSelectedMedia] = React.useState<string | null>(null);
  const [showPenetration, setShowPenetration] = React.useState<boolean>(false);
  const penetrationRadarData = {
    labels: radarPenetrationData.labels,
    datasets: [
      {
        data: radarPenetrationData.values,
        backgroundColor: "rgba(0, 176, 204, 0.4)",
        borderColor: "#00B0CC",
        borderWidth: 2,
      },
    ],
  };
  const indexRadarData = {
    labels: radarIndexData.labels,
    datasets: [
      {
        data: radarIndexData.values,
        backgroundColor: "rgba(0, 176, 204, 0.4)",
        borderColor: "#00B0CC",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        ticks: {
          display: false,
        },
        grid: {
          color: "rgba(132, 138, 146, 1)",
          circular: true,
        },
        angleLines: {
          color: "rgba(132, 138, 146, 1)",
        },
        pointLabels: {
          color: "rgba(255, 255, 255, 1)",
          // fontColor: radarData.labels.map(x => "#00B0CC")
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: "white",
        },
      },
    },
    onClick(event: any, elements: ActiveElement[], chart: any) {
      onChartClick(event, elements, chart);
    },
    onHover(event: any, elements: ActiveElement[], chart: any) {
      onChartHover(event, elements, chart);
    },
  };

  useEffect(() => {
    setSelectedMedia(media[0].variable_name);
  }, []);

  useEffect(() => {
    setRadarIndexData(computeMediaData(media, "index"));
    setRadarPenetrationData(computeMediaData(media, "penetration"));

    if (selectedMedia) {
      const selectedMedium = media.find(
        (medium) => medium.variable_name === selectedMedia
      );
      if (selectedMedium) {
        setTopMedia(reformatData(selectedMedium.media));
      }
    }
  }, [media, selectedMedia]);

  const onChartClick = (event: any, elements: ActiveElement[], chart: any) => {
    const { x, y } = event;
    let index = -1;
    chart.scales.r._pointLabelItems.forEach((item: any, i: number) => {
      const { bottom, top, left, right } = item;
      if (x >= left && x <= right && y >= top && y <= bottom) {
        index = i;
      }

      if (index === -1) {
        return;
      }

      const clickedLabel = chart.scales.r._pointLabels[index];
      setSelectedMedia(clickedLabel);
    });
  };

  const onChartHover = (event: any, elements: ActiveElement[], chart: any) => {
    const { x, y } = event;
    let index = -1;
    chart.scales.r._pointLabelItems.forEach((item: any, i: number) => {
      const { bottom, top, left, right } = item;
      if (x >= left && x <= right && y >= top && y <= bottom) {
        index = i;
      }

      if (index === -1) {
        event.native.target.style.cursor = "default";
        return;
      }

      const hoverLabel = chart.scales.r._pointLabels[index];
      event.native.target.style.cursor = hoverLabel ? "pointer" : "default";
    });
  };

  return (
    <Grid container item xs={12} spacing={6} mb={4}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography variant="subtitle1" mb={2}>
          Media Channel Preference
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                value={showPenetration}
                onClick={() => setShowPenetration(!showPenetration)}
              />
            }
            label={showPenetration ? "Penetration" : "Index"}
          />
        </FormGroup>
        <div className="chart-container radar-chart">
          <Radar
            ref={chartRef}
            options={options}
            data={showPenetration ? penetrationRadarData : indexRadarData}
          />
        </div>
      </Grid>
      {selectedMedia && (
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="h6"
            mb={1}
            mt={2}
            sx={{
              color: "var(--color-primary)",
            }}
          >
            {selectedMedia}
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            Top Brands
          </Typography>
          <Table id="top-media" data={topMedia} columns={topTitlesColumns} />
        </Grid>
      )}
    </Grid>
  );
};
