import React, {FC, useEffect, useState} from "react";
import {Brand, SpendCategory, Vertical} from "@/types";
import { reformatData } from "@/utils/common";
import { Grid, Typography } from "@mui/material";
import { Table } from "@/components/molecules";
import { topCategoriesColumns } from "@/components/molecules/table";

type SpendTabProps = {
  categories: SpendCategory[];
};

export const SpendTab: FC<SpendTabProps> = ({ categories }) => {

  return (
    <Grid container item xs={12} spacing={6} mb={4}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle1" mb={2}>
          Top Categories
        </Typography>
        <Table
          id="spend"
          data={categories}
          columns={topCategoriesColumns}
        />
      </Grid>
    </Grid>
  );
};
