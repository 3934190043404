import React from "react";

import { Navbar } from "@/components/molecules";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "@/components/atoms";
import { PoweredByLogoDark } from "@/assets/images";
import { Grid } from "@mui/material";

interface PageLayoutProps {
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="page-layout">
      <Navbar appName="Fan Insights Dashboard" displayAppName={true} />
      <div className="page-content">
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          mt={4}
          width="100%"
          minWidth="1024px"
        >
          {children}
        </Grid>
      </div>
      <div className="footer">
        <div className="logo">
          <PoweredByLogoDark />
        </div>
      </div>
    </div>
  );
};
