import React from "react";

export const ClvLogo = () => (
  <svg viewBox="0 0 1266 669">
    <path id="Selection"
          fill="#FFFFFF"
          d="M 234.00,582.00
           C 212.38,603.87 185.24,628.12 159.00,644.19
             144.34,653.17 122.43,663.92 105.00,664.00
             105.00,664.00 94.00,664.00 94.00,664.00
             85.76,663.90 77.52,661.53 70.00,658.28
             40.77,645.67 22.14,617.32 12.33,588.00
             8.84,577.57 6.14,566.88 4.44,556.00
             4.44,556.00 2.04,526.00 2.04,526.00
             2.04,526.00 2.91,518.00 2.91,518.00
             2.91,518.00 4.30,500.00 4.30,500.00
             10.22,459.79 32.94,421.22 74.00,409.58
             84.66,406.56 95.92,404.87 107.00,405.00
             119.64,405.15 139.45,409.20 147.20,420.01
             154.17,429.73 152.97,451.23 137.00,449.89
             128.50,449.18 121.97,440.31 109.00,444.76
             97.21,448.81 89.43,462.16 84.72,473.00
             76.82,491.16 71.03,521.18 71.00,541.00
             71.00,541.00 71.00,558.00 71.00,558.00
             71.01,565.93 72.89,579.19 74.65,587.00
             76.51,595.21 77.62,600.21 81.32,608.00
             83.06,611.65 85.79,615.89 88.46,618.91
             106.48,639.28 137.19,619.88 154.00,607.37
             154.00,607.37 165.00,598.58 165.00,598.58
             165.00,598.58 178.00,587.00 178.00,587.00
             178.00,587.00 194.00,571.00 194.00,571.00
             194.00,571.00 212.87,550.00 212.87,550.00
             212.87,550.00 235.34,522.00 235.34,522.00
             238.12,516.69 234.02,502.33 233.13,496.00
             230.91,480.21 226.18,445.79 225.96,431.00
             225.96,431.00 225.96,420.00 225.96,420.00
             225.96,420.00 225.00,408.00 225.00,408.00
             225.00,408.00 225.00,351.00 225.00,351.00
             225.00,351.00 225.96,339.00 225.96,339.00
             225.96,339.00 225.96,327.00 225.96,327.00
             225.96,327.00 228.83,296.00 228.83,296.00
             232.24,260.84 237.66,226.43 245.77,192.00
             255.18,152.00 271.05,105.74 293.05,71.00
             300.70,58.92 309.20,47.37 319.09,37.01
             337.62,17.60 361.42,5.49 388.00,2.83
             406.84,0.95 422.15,5.37 435.91,19.09
             454.26,37.37 464.96,71.39 465.00,97.00
             465.00,97.00 465.00,121.00 465.00,121.00
             465.00,121.00 463.17,140.00 463.17,140.00
             460.62,165.45 455.27,190.37 448.42,215.00
             427.84,289.02 394.20,357.33 354.80,423.00
             354.80,423.00 313.28,486.00 313.28,486.00
             313.28,486.00 299.87,504.00 299.87,504.00
             298.05,506.43 294.04,511.33 293.22,514.00
             291.84,518.45 294.77,535.56 295.58,541.00
             298.67,561.81 305.72,597.45 318.53,614.00
             320.77,616.91 325.08,621.25 328.00,623.47
             345.09,636.44 364.17,616.40 374.56,604.00
             392.22,582.92 408.84,548.34 417.02,522.00
             420.81,509.83 424.98,493.71 425.00,481.00
             425.00,481.00 425.00,475.00 425.00,475.00
             425.00,475.00 423.00,450.00 423.00,450.00
             423.00,450.00 423.00,436.00 423.00,436.00
             423.09,428.85 424.96,417.39 430.21,412.22
             436.30,406.23 464.97,397.89 473.32,417.00
             475.37,421.70 475.77,427.90 476.59,433.00
             476.59,433.00 481.42,467.00 481.42,467.00
             481.42,467.00 490.28,532.00 490.28,532.00
             490.28,532.00 496.00,575.00 496.00,575.00
             499.17,571.42 500.40,567.35 502.28,563.00
             502.28,563.00 512.81,536.00 512.81,536.00
             521.55,512.94 530.17,479.35 535.58,455.00
             535.58,455.00 548.06,403.00 548.06,403.00
             549.26,399.88 551.25,395.76 553.10,393.00
             560.15,382.55 571.52,382.61 579.47,392.09
             585.35,399.10 587.89,411.02 588.00,420.00
             588.27,443.79 580.03,461.14 580.00,479.00
             580.00,479.00 580.00,484.00 580.00,484.00
             580.01,489.50 580.07,491.63 581.72,497.00
             582.71,500.21 584.01,503.21 585.91,505.79
             596.68,521.73 615.11,513.60 628.00,505.79
             628.00,505.79 684.00,463.61 684.00,463.61
             701.46,452.99 718.03,449.18 738.00,446.72
             738.00,446.72 757.00,445.00 757.00,445.00
             757.00,445.00 780.00,445.00 780.00,445.00
             780.00,445.00 797.00,444.00 797.00,444.00
             797.00,444.00 825.00,444.00 825.00,444.00
             825.00,444.00 842.00,443.00 842.00,443.00
             842.00,443.00 881.00,443.00 881.00,443.00
             881.00,443.00 895.00,442.00 895.00,442.00
             895.00,442.00 924.00,442.00 924.00,442.00
             924.00,442.00 941.00,441.00 941.00,441.00
             941.00,441.00 969.00,441.00 969.00,441.00
             969.00,441.00 986.00,440.00 986.00,440.00
             986.00,440.00 1031.00,440.00 1031.00,440.00
             1031.00,440.00 1048.00,439.00 1048.00,439.00
             1048.00,439.00 1076.00,439.00 1076.00,439.00
             1076.00,439.00 1093.00,438.00 1093.00,438.00
             1093.00,438.00 1164.00,438.00 1164.00,438.00
             1164.00,438.00 1179.00,437.00 1179.00,437.00
             1179.00,437.00 1198.00,437.00 1198.00,437.00
             1198.00,437.00 1211.17,436.03 1211.17,436.03
             1211.17,436.03 1221.00,436.03 1221.00,436.03
             1221.00,436.03 1231.00,435.00 1231.00,435.00
             1231.00,435.00 1245.00,435.00 1245.00,435.00
             1250.40,435.01 1254.40,435.09 1258.90,438.64
             1266.50,444.63 1266.47,456.79 1260.61,463.96
             1254.00,472.03 1238.90,473.95 1229.00,474.00
             1229.00,474.00 1225.00,474.00 1225.00,474.00
             1225.00,474.00 1210.00,473.00 1210.00,473.00
             1210.00,473.00 1187.00,472.00 1187.00,472.00
             1187.00,472.00 1165.00,472.00 1165.00,472.00
             1165.00,472.00 1152.00,471.00 1152.00,471.00
             1152.00,471.00 1138.00,471.00 1138.00,471.00
             1138.00,471.00 1122.00,470.00 1122.00,470.00
             1122.00,470.00 1081.00,470.00 1081.00,470.00
             1081.00,470.00 1064.00,469.00 1064.00,469.00
             1064.00,469.00 946.00,469.00 946.00,469.00
             946.00,469.00 931.00,470.00 931.00,470.00
             931.00,470.00 891.00,470.00 891.00,470.00
             891.00,470.00 874.00,471.04 874.00,471.04
             874.00,471.04 858.00,471.04 858.00,471.04
             858.00,471.04 846.00,472.00 846.00,472.00
             846.00,472.00 825.00,472.00 825.00,472.00
             825.00,472.00 808.00,473.00 808.00,473.00
             808.00,473.00 777.00,474.00 777.00,474.00
             777.00,474.00 757.00,475.00 757.00,475.00
             757.00,475.00 746.00,475.92 746.00,475.92
             727.24,477.42 708.52,480.35 692.00,490.01
             659.31,509.14 637.37,542.48 596.00,542.00
             582.30,541.84 569.08,534.09 562.52,522.00
             560.15,517.63 559.70,512.64 558.00,508.00
             558.00,508.00 543.42,560.00 543.42,560.00
             536.83,583.71 529.71,611.32 520.80,634.00
             514.60,649.76 506.06,668.14 486.00,666.91
             472.12,666.05 464.04,654.69 458.86,643.00
             451.20,625.72 446.90,605.51 443.20,587.00
             441.42,578.08 438.08,562.58 438.00,554.00
             433.59,558.14 431.95,564.54 429.58,570.00
             429.58,570.00 417.58,594.00 417.58,594.00
             406.10,614.31 392.53,635.26 374.00,649.79
             364.07,657.58 351.87,663.84 339.00,664.00
             328.22,664.12 320.95,663.18 311.00,658.51
             304.06,655.25 297.47,650.32 292.00,644.99
             282.42,635.64 276.05,626.56 269.43,615.00
             260.62,599.61 254.50,582.79 249.00,566.00
             249.00,566.00 234.00,582.00 234.00,582.00 Z
           M 303.95,423.00
           C 303.95,423.00 336.25,367.00 336.25,367.00
             336.25,367.00 349.74,342.00 349.74,342.00
             370.35,302.07 388.63,261.20 404.05,219.00
             413.34,193.60 426.68,149.63 427.00,123.00
             427.00,123.00 427.00,107.00 427.00,107.00
             426.98,93.31 422.48,68.57 410.83,60.11
             405.78,56.44 399.07,55.50 393.00,56.29
             389.74,56.71 386.01,57.58 383.00,58.88
             378.27,60.91 373.94,64.31 370.01,67.61
             352.52,82.37 339.40,114.67 331.42,136.00
             320.81,164.37 313.14,200.11 307.54,230.00
             302.85,255.07 298.72,280.61 296.17,306.00
             296.17,306.00 293.00,336.00 293.00,336.00
             293.00,336.00 293.00,345.00 293.00,345.00
             293.00,345.00 290.00,392.00 290.00,392.00
             290.00,392.00 289.00,409.00 289.00,409.00
             289.00,409.00 289.00,444.00 289.00,444.00
             294.29,440.85 300.43,428.63 303.95,423.00 Z
           M 785.00,628.00
           C 785.00,630.27 785.22,633.66 783.98,635.61
             782.28,638.27 776.94,640.03 774.00,640.97
             762.20,644.74 759.13,644.14 747.00,644.00
             725.57,643.74 707.25,622.96 707.00,602.00
             706.92,594.66 706.69,589.07 709.24,582.00
             715.81,563.80 734.67,551.28 754.00,552.04
             761.57,552.34 770.47,555.06 777.00,558.88
             779.16,560.13 782.93,562.46 783.55,565.04
             784.51,569.07 778.83,573.82 775.00,572.56
             772.39,571.69 764.92,564.84 755.00,564.09
             735.63,562.63 719.29,581.38 720.04,600.00
             720.74,617.35 735.65,631.92 753.00,632.00
             758.04,632.02 769.01,631.47 771.98,626.73
             773.00,625.08 772.96,622.87 773.00,621.00
             773.00,621.00 773.00,608.00 773.00,608.00
             769.63,608.00 753.38,608.41 751.43,607.43
             748.63,606.02 748.21,599.91 750.60,598.02
             751.93,596.96 754.38,597.03 756.00,597.00
             756.00,597.00 785.00,597.00 785.00,597.00
             785.00,597.00 785.00,628.00 785.00,628.00 Z
           M 980.00,553.50
           C 1018.92,562.43 1027.20,613.08 996.00,636.24
             984.98,644.42 976.90,644.15 964.00,644.00
             933.16,643.63 915.88,609.05 925.55,582.00
             929.57,570.72 939.84,559.87 951.00,555.46
             955.39,553.73 959.41,553.18 964.00,552.43
             969.88,551.70 974.24,552.18 980.00,553.50 Z
           M 856.00,554.00
           C 863.13,554.00 869.45,553.39 876.00,556.91
             888.27,563.52 893.69,579.33 887.60,592.00
             884.04,599.41 878.32,603.73 871.00,607.00
             875.08,614.72 887.68,636.04 889.00,643.00
             886.07,643.00 877.80,643.52 875.63,642.01
             873.80,640.72 871.80,636.10 870.75,634.00
             868.58,629.66 860.34,611.79 857.61,609.60
             854.44,607.04 841.51,608.00 837.00,608.00
             837.00,608.00 837.00,643.00 837.00,643.00
             837.00,643.00 825.00,643.00 825.00,643.00
             825.00,643.00 825.00,554.00 825.00,554.00
             825.00,554.00 856.00,554.00 856.00,554.00 Z
           M 1064.00,554.00
           C 1064.00,554.00 1064.00,605.00 1064.00,605.00
             1064.01,612.83 1064.81,618.80 1070.21,624.98
             1080.05,636.26 1097.11,634.68 1105.01,622.00
             1109.70,614.47 1109.00,605.46 1109.00,597.00
             1109.00,597.00 1109.00,554.00 1109.00,554.00
             1109.00,554.00 1121.00,554.00 1121.00,554.00
             1121.00,554.00 1121.00,599.00 1121.00,599.00
             1121.00,612.28 1122.40,621.74 1113.32,632.96
             1101.43,647.65 1074.26,648.09 1061.18,634.83
             1054.22,627.76 1052.01,619.59 1052.00,610.00
             1052.00,610.00 1052.00,554.00 1052.00,554.00
             1052.00,554.00 1064.00,554.00 1064.00,554.00 Z
           M 1191.00,554.00
           C 1196.93,554.00 1202.38,553.57 1208.00,555.95
             1225.63,563.43 1229.61,588.33 1216.70,601.83
             1210.97,607.82 1205.11,609.90 1197.00,610.00
             1193.45,610.04 1181.77,609.39 1179.31,611.02
             1176.55,612.86 1177.01,617.09 1177.00,620.00
             1177.00,620.00 1177.00,636.00 1177.00,636.00
             1176.96,637.95 1177.10,640.63 1175.40,641.98
             1173.60,643.40 1167.41,643.00 1165.00,643.00
             1165.00,643.00 1165.00,554.00 1165.00,554.00
             1165.00,554.00 1191.00,554.00 1191.00,554.00 Z
           M 935.00,597.00
           C 934.97,599.93 934.90,603.13 935.52,606.00
             938.91,621.90 954.53,634.33 971.00,632.70
             976.61,632.15 982.18,630.72 987.00,627.73
             1017.18,608.98 1002.29,560.78 965.00,564.29
             948.30,567.10 935.21,579.47 935.00,597.00 Z
           M 837.60,567.31
           C 836.93,568.67 837.01,571.46 837.00,573.00
             837.00,573.00 837.00,591.00 837.00,591.00
             837.04,592.96 836.85,595.47 838.57,596.83
             840.84,598.61 851.69,598.01 855.00,598.00
             860.57,597.99 866.25,597.80 870.96,594.35
             879.95,587.76 879.50,575.22 870.96,568.42
             869.15,566.97 867.24,566.07 865.00,565.56
             861.00,564.56 843.32,564.63 839.31,565.56
             838.56,566.24 838.34,565.80 837.60,567.31 Z
           M 1190.00,599.00
           C 1194.01,598.99 1198.26,599.04 1202.00,597.35
             1204.64,596.16 1206.82,594.24 1208.61,591.98
             1215.18,583.72 1212.21,572.28 1203.00,567.44
             1197.47,564.53 1190.12,564.89 1184.00,565.00
             1176.51,565.14 1177.01,567.26 1177.00,574.00
             1177.00,574.00 1177.00,599.00 1177.00,599.00
             1177.00,599.00 1190.00,599.00 1190.00,599.00 Z" />
  </svg>
);
