import React from "react";

export const PoweredByLogoDark = () => (
  <svg viewBox="0 0 264 56">
    <g
      fill="#5e677c"
      transform="translate(0.80535015,0.23010004)"
      id="g3886">
      <g
        fill="#5e677c"
        id="g3884">
        <g
          fill="#5e677c"
          id="g3882">
          <g
            fill="#5e677c"
            id="g3870">
            <g
              fill="#5e677c"
              id="g3868">
              <path
                fill="#5e677c"
                id="path3850"
                d="m 11.8,44 1.7,-1.3 c 1.5,2.2 3.3,3.3 5.4,3.3 1.2,0 2.1,-0.3 2.8,-0.9 0.7,-0.6 1.1,-1.4 1.1,-2.4 0,-0.8 -0.2,-1.4 -0.7,-1.9 -0.5,-0.4 -1.3,-0.9 -2.5,-1.5 l -2.2,-1 c -1.5,-0.7 -2.6,-1.4 -3.3,-2.2 -0.7,-0.8 -1.1,-1.8 -1.1,-3 0,-1.5 0.6,-2.7 1.7,-3.7 1.1,-0.9 2.5,-1.4 4.2,-1.4 1.3,0 2.4,0.3 3.5,0.9 1.1,0.6 1.9,1.4 2.6,2.4 l -1.7,1.3 c -1.1,-1.6 -2.6,-2.4 -4.4,-2.4 -1,0 -1.8,0.3 -2.5,0.8 -0.7,0.5 -1,1.2 -1,2.1 0,0.6 0.1,1 0.4,1.4 0.2,0.4 0.5,0.6 0.9,0.9 0.4,0.3 1,0.6 1.8,1 l 2.1,1 c 1.6,0.8 2.7,1.6 3.5,2.4 0.7,0.8 1.1,1.8 1.1,3 0,1.6 -0.6,2.9 -1.8,3.9 -1.2,1 -2.7,1.5 -4.5,1.5 -1.5,0 -2.9,-0.4 -4.1,-1.1 -1.3,-0.8 -2.3,-1.8 -3,-3.1 z"
                className="st0" />
              <path
                fill="#5e677c"
                id="path3852"
                d="M 43.2,47.8 V 30.4 h -6.8 v -2.1 h 16 v 2.1 h -6.9 v 17.4 z"
                className="st0" />
              <path
                fill="#5e677c"
                id="path3854"
                d="M 75.7,47.8 74.1,43.9 H 64.6 L 63,47.8 H 60.6 L 69,28 h 0.9 l 8.4,19.8 z m -2.5,-6 -3.9,-9.6 -3.9,9.6 z"
                className="st0" />
              <path
                fill="#5e677c"
                id="path3856"
                d="M 105.7,47.8 H 103 l -6,-8.2 h -4.1 v 8.2 H 90.6 V 28.4 h 6.8 c 1.9,0 3.4,0.5 4.5,1.6 1.1,1.1 1.7,2.4 1.7,4 0,1.3 -0.4,2.4 -1.1,3.4 -0.7,1 -1.7,1.6 -3,2 z M 92.9,30.4 v 7.2 h 4.4 c 1.2,0 2.2,-0.3 2.9,-1 0.7,-0.6 1,-1.5 1,-2.6 0,-1.1 -0.4,-1.9 -1,-2.6 -0.7,-0.7 -1.7,-1 -2.9,-1 z"
                className="st0" />
              <path
                fill="#5e677c"
                id="path3858"
                d="m 116.2,38.1 c 0,-2.8 1,-5.2 3,-7.2 2.1,-2 4.5,-3 7.3,-2.9 1.8,0 3.5,0.4 5,1.3 1.5,0.9 2.7,2 3.5,3.5 l -3,2.1 c -1.4,-2.2 -3.2,-3.3 -5.6,-3.3 -1.8,0 -3.3,0.6 -4.5,1.8 -1.2,1.2 -1.8,2.7 -1.8,4.6 0,1.8 0.6,3.4 1.8,4.6 1.2,1.2 2.7,1.8 4.5,1.8 2.3,0 4.2,-1.1 5.6,-3.3 l 3,2.1 c -0.8,1.5 -2,2.7 -3.5,3.5 -1.5,0.9 -3.2,1.3 -5.1,1.3 -2.8,0.1 -5.3,-0.9 -7.3,-2.9 -2,-1.8 -3,-4.2 -2.9,-7 z"
                className="st0" />
              <path
                fill="#5e677c"
                id="path3860"
                d="m 146,38.1 c 0,-2.8 1,-5.2 3,-7.2 2,-2 4.5,-3 7.3,-2.9 2.8,-0.1 5.2,0.9 7.3,2.9 2.1,2 3.1,4.4 3,7.2 0,2.8 -1,5.2 -3,7.2 -2.1,2 -4.5,3 -7.3,2.9 -2.8,0.1 -5.2,-0.9 -7.3,-2.9 -2,-2.1 -3,-4.4 -3,-7.2 z m 16.6,0 c 0,-1.8 -0.6,-3.4 -1.8,-4.6 -1.2,-1.2 -2.7,-1.8 -4.5,-1.8 -1.8,0 -3.3,0.6 -4.5,1.8 -1.2,1.2 -1.8,2.7 -1.8,4.6 0,1.9 0.6,3.4 1.8,4.6 1.2,1.2 2.7,1.8 4.5,1.8 1.8,0 3.3,-0.6 4.5,-1.8 1.2,-1.2 1.8,-2.7 1.8,-4.6 z"
                className="st0" />
              <path
                fill="#5e677c"
                id="path3862"
                d="M 178.7,39.8 V 28.4 h 4 v 11.7 c 0,1.4 0.4,2.5 1.2,3.3 0.8,0.8 1.8,1.2 3.1,1.2 1.3,0 2.3,-0.4 3.1,-1.2 0.8,-0.8 1.2,-1.9 1.2,-3.3 V 28.4 h 4 v 11.4 c 0,2.5 -0.8,4.6 -2.3,6.1 -1.5,1.5 -3.5,2.3 -5.9,2.3 -2.4,0 -4.4,-0.8 -5.9,-2.3 -1.7,-1.6 -2.5,-3.6 -2.5,-6.1 z"
                className="st0" />
              <path
                fill="#5e677c"
                id="path3864"
                d="m 220.9,28.4 h 4 v 19.8 h -1.3 l -11.2,-12 v 11.7 h -4 V 28 h 1.3 L 221,40 V 28.4 Z"
                className="st0" />
              <path
                fill="#5e677c"
                id="path3866"
                d="M 242.6,47.8 V 32 h -6.2 v -3.6 h 16.4 V 32 h -6.2 v 15.8 z"
                className="st0" />
            </g>
          </g>
          <g
            fill="#5e677c"
            id="g3880">
            <g
              fill="#5e677c"
              id="g3874">
              <path
                fill="#5e677c"
                id="path3872"
                d="m 244.1,18.6 c 0,0 -24.4,-10.4 -59.7,-10 -45,0.6 -66.6,13.2 -66.6,13.2 0,0 30.8,-13 74.2,-11.1 38,1.6 51.5,8.9 51.5,8.9 z"
                className="st0" />
            </g>
            <g
              fill="#5e677c"
              id="g3878">
              <circle
                fill="#5e677c"
                id="circle3876"
                r="2.9000001"
                cy="19.4"
                cx="244"
                className="st0" />
            </g>
          </g>
        </g>
      </g>
      <text
        id="text4324"
        y="21.594694"
        x="10.469553"
        style={{
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '40px',
          lineHeight: '1.25',
          fontFamily: 'sans-serif',
          letterSpacing: '0px',
          wordSpacing: '0px',
          fill: '#5e677c',
          fillOpacity: 1,
          stroke: 'none'
        }}
        >
        <tspan
         style={{
           fontStyle: 'normal',
           fontVariant: 'normal',
           fontWeight: 'normal',
           fontStretch: 'normal',
           fontSize: '17.33333397px',
           fontFamily: 'Cera Pro',
           textAlign: 'start'
         }}
         y="19.594694"
         x="10.469553"
         id="tspan4322"
        >
          Powered By
        </tspan>
      </text>
    </g>
  </svg>
);
